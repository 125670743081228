import type { TPAPageId } from '@wix/platform-editor-sdk';
import type { ISiteApis, PlatformControllerFlowAPI, StructurePage } from '@wix/yoshi-flow-editor';
import { PAGE_DATA } from 'root/consts';

export const getHostingEnvironment = (environment: PlatformControllerFlowAPI['environment']) => {
  if (environment.isEditor) {
    return 'editor';
  } else if (environment.isEditorX) {
    return 'editor x';
  } else if (environment.isADI) {
    return 'adi';
  } else if (environment.isPreview) {
    return 'preview';
  } else if (environment.isMobile) {
    return 'mobile';
  } else if (environment.isViewer) {
    return 'viewer';
  } else {
    return undefined;
  }
};

export const getIsCurrentPageMenusDefaultPage = async (site: ISiteApis) => {
  const allPages = (await site.getSiteStructure({ includePageId: true })).pages as (StructurePage & {
    tpaPageId: TPAPageId;
  })[];
  const currentPageTpaId = allPages.find((page) => page.id === site.currentPage?.id)?.tpaPageId;
  return currentPageTpaId === PAGE_DATA.pageId;
};
