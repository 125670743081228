import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import type { GetAllResponse } from 'root/api/utils/types';
import type { PopulatedMenu } from 'root/apiTypes';
import { getPriceFormatter } from 'root/utils/currency';
import { goToPage } from 'root/utils/navigation';
import { WarmupDataManager } from 'root/utils/WarmupDataManager';
import { restaurantsUouPageFinishedLoading } from '@wix/bi-logger-restaurants-data/v2';
import { getHostingEnvironment, getIsCurrentPageMenusDefaultPage } from 'root/utils/bi';
import MenuWidget from 'root/components/Menus/.component.json';
import { MENU_WIDGET_NAME, PAGE_NAME } from 'root/consts';
import { getPopulatedMenusWithFormattedPrice } from 'root/utils/getPopulatedMenusWithFormattedPrice';

let menusPromise: Promise<{ data: GetAllResponse<PopulatedMenu> | undefined; error: Error | undefined }> | undefined;

const createController: CreateControllerFn = ({ flowAPI }) => {
  const priceFormatter = getPriceFormatter(flowAPI);
  const navigateTo = goToPage(flowAPI);

  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment.isSSR
  );
  const { site } = flowAPI?.controllerConfig?.wixCodeApi;
  const getMonitoredPopulatedMenuClient = () =>
    getMonitoredApiCall(() => PopulatedMenuClient(flowAPI.essentials.httpClient).getAll({}), flowAPI.reportError);

  if (!menusPromise) {
    menusPromise = warmupData.manageData<{
      data: GetAllResponse<PopulatedMenu> | undefined;
      error: Error | undefined;
    }>(getMonitoredPopulatedMenuClient, 'populatedMenus');
  }
  return {
    async pageReady() {
      if (menusPromise) {
        const { data: menus } = (await menusPromise) || {};
        flowAPI.controllerConfig.setProps({
          menus: menus?.data ? getPopulatedMenusWithFormattedPrice(menus.data, priceFormatter) : [],
          navigateTo,
        });
      }
      const isCurrentPageMenusDefaultPage = await getIsCurrentPageMenusDefaultPage(site);
      flowAPI.bi?.report(
        restaurantsUouPageFinishedLoading({
          hosting: getHostingEnvironment(flowAPI.environment),
          pageName: PAGE_NAME,
          product: PAGE_NAME,
          widgetId: MenuWidget.id,
          widgetInstanceId: flowAPI.controllerConfig.compId,
          widgetName: MENU_WIDGET_NAME,
          isMenusDefaultPage: isCurrentPageMenusDefaultPage,
          isOoi: true,
        })
      );
    },
  };
};

// eslint-disable-next-line import/no-default-export
export default createController;
